    <template>
        <div id="contacts-container" class="text-center pt-5">
        <h2 class="fs-1">{{ $t('Home.btn_contact') }}</h2>  
        <section class="d-flex flex-column justify-content-center align-items-center flex-wrap">

            <div id="contacts" class="pt-5 pb-5 d-flex flex-row justify-content-center align-items-center flex-wrap">
                
                <a href="https://api.whatsapp.com/send?phone=5582993975054" target="_blank" class="column fs-5 m-3 p-2 rounded fs-5" id="btn-whatsapp" title="Contate-nos via WhatsApp" aria-label="Contate-nos via WhatsApp">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                    <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
                    </svg>
                    <span class="m-2 mt-0 mb-0">Whatsapp</span>
                </a>            


                <a href="https://www.instagram.com/affaridevelopment/" target="_blank" class="column fs-5 m-3 p-2 rounded fs-5" id="btn-instagram" title="Contate-nos via WhatsApp" aria-label="Contate-nos via WhatsApp">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                    </svg>
                    <span class="m-2 mt-0 mb-0">Instagram</span>                                
                </a>             


                <a href="mailto:wemerson.azevedo@iqb.ufal.br" class="column fs-5 m-3 p-2 rounded fs-5" id="btn-email" title="Contate-nos via WhatsApp" aria-label="Contate-nos via WhatsApp">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-envelope-at" viewBox="0 0 16 16">
                    <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z"/>
                    <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648m-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/>
                    </svg>                
                    <span class="m-2 mt-0 mb-0">E-mail</span>
                </a>

            </div>           

            <span class="fs-2 mt-5">{{ $t('Home.fill_the_form') }}</span>
    
            <form @submit.prevent="submitForm" id="form" class="col-10 col-md-9 col-lg-7 pt-5">
                <div class="d-flex flex-column flex-md-row align-items-center col-12" id="double-fields">
                    <div class="mt-4 col text-start fs-5">
                        <label for="name">{{ $t('Home.name') }}</label>
                        <input id="name" v-model="form.name" type="text" class="rounded p-4 pt-1 pb-1" minlength="3" required>
                    </div>
                    <div class="mt-4 col text-start fs-5">
                        <label for="phone">{{ $t('Home.phone') }}</label>
                        <input id="phone" placeholder="5511912345678" v-model="form.phone" type="tel" class="rounded p-4 pt-1 pb-1" pattern="[0-9]{3,20}" minlength="3" required>
                    </div>
                </div>
    
                <div class="mt-4 form-group text-start fs-5">    
                    <label for="email">E-mail</label>
                    <input id="email" placeholder="Ex: id@gmail.com" v-model="form.email" type="email" class="rounded d-block col-12 p-4 pt-1 pb-1" required>        
                </div>
                <div class="mt-4 form-group text-start fs-5">
                    <label for="message">{{ $t('Home.message') }}</label>
                    <textarea id="message" v-model="form.message" class="rounded col-12 p-4 pt-1 pb-1" rows="3" minlength="10" required></textarea>                 
                </div>
                <div class="mt-5 d-flex justify-content-center justify-content-md-end align-items-center">
                    <button id="btn-submit" type="submit" class="fs-5 mt-5">{{ $t('Home.submit') }}</button>
                </div>            
            </form>     

        </section>  

            <div v-if="visibleModal" id="modal" class="d-flex flex-column justify-content-center align-items-center rounded">
         
                <svg id="close" v-if="showX" @click="closeModal" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x m-3 position-absolute top-0 end-0" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                </svg>

                <div>                 
                    <span class="fs-6 fs-md-5">{{ textModal_1 }}</span>    
                    <span class="fs-6 fs-md-5 d-block">{{ textModal_2 }}</span>                                                     
                </div>
            
            </div>
        </div>
    </template>
    
    <script>
    import axios from 'axios';
    
    export default {
    name: 'Contacts', 
    data() {
        return {
            form: {
            email: '',
            name: '',
            phone: '',
            message: ''
            },
            visibleModal: false,
            showX: false,
            btnIsDisabled: false, 
            textModal_1: '',
            textModal_2: '',                      
        };
    },
    methods: {
        async submitForm() {

            this.changeBtnState(true); 
            this.showModal();
            this.changeTextModal(this.$t('Home.sending'), '');

            try {
                await axios.post('https://affari-send-email.vercel.app/send-email', this.form);                                
                this.changeTextModal(this.$t('Home.sent_successfully'), this.$t('Home.we_will_return')); 
                this.showBtnClose(true);            
            } catch (error) {
                console.error('Erro ao enviar o formulário:', error);                   
                this.changeTextModal(this.$t('Home.sending_error'), ''); 
                this.showBtnClose(true);              
            }
        },

        disableButton() {
            const btnSubmit = document.querySelector('#btn-submit');
            btnSubmit.disabled = this.btnIsDisabled;
        },

        changeBtnState(state) {
            this.btnIsDisabled = state;            
            this.disableButton();            
        },

        changeTextModal(firstText, secondText) {                         
            this.textModal_1 = firstText; 
            this.textModal_2 = secondText;
        },

        showModal() {
            this.visibleModal = true;                
        },

        showBtnClose(visibility) {
            this.showX = visibility;
        },

        closeModal() {
            this.changeBtnState(false);
            this.visibleModal = false;
            this.resetForm();             
        },
        resetForm() {
            this.form.email = '';
            this.form.name = ''; 
            this.form.phone = '';
            this.form.message = '';
        }     
    },
    mounted() {        

    },  
    }
    </script>


    <style scoped>

    #contacts-container {
        background-color: var(--black);
        height: auto;
        width: 100%; 
        padding: 200px 0 100px 0;
    }

    section, h2{
        background-color: var(--black);
        margin: 0;
        padding: 20px 0;
    }

    #btn-whatsapp, #btn-instagram, #btn-email {
        background-color: var(--darkolivegreen);        
        width: 200px;  
        color: var(--white);
        font-weight: normal;  
        border: solid 1px var(--black);
    } 

    #btn-whatsapp:hover, #btn-instagram:hover, #btn-email:hover {
        background-color: var(--darkgrey);
        border: solid 1px var(--darkolivegreen); 
        color: var(--darkolivegreen);   
        transition: 1s;    
    }

    textarea {
        height: 150px;
    }

    input, textarea {
        border: none;
        border-bottom: solid 0.5px var(--darkolivegreen);
        background-color: var(--darkgray);
        color: var(--white) !important;
        outline: none;
        padding: 2px;
    }

    input:focus, textarea:focus { 
        border: solid 0.5px var(--darkolivegreen);        
    }

    #name {
        width: 98%;
    }

    #phone {
        width: 100%;
    }

    button {
        background-color: var(--darkolivegreen);
        color: var(--white);
        padding: 10px 0;
        width: 220px;
        border-radius: 50px;
        border: solid 1px var(--black);
    }

    button:hover {
        background-color: var(--darkgrey);
        border: solid 1px var(--darkolivegreen); 
        color: var(--darkolivegreen);   
        transition: 1s; 
    }
    
    #modal {
        width: 400px;
        height: 150px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--darkgray);
        border: solid 2px var(--darkolivegreen);    
        color: var(--white);   
    }

    #close:hover {
        cursor: pointer;         
    }

    @media(max-width: 768px) { 

        #double-fields div {
            width: 100%;
        }

        #name {
            width: 100%;
        } 
        
        #btn-whatsapp, #btn-instagram, #btn-email {
            width: 80%;
        }

        #modal {
            width: 90%;
        }
    } 

    </style>
