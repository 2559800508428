import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-64efff98"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "select-lang",
  class: "d-flex flex-row justify-content-around align-items-center justify-content-md-end"
}
const _hoisted_2 = {
  id: "langs",
  class: "m-0 m-md-3"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "m-1",
        onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.setLanguageEnglish();}),
        src: _ctx.english,
        id: "english"
      }, null, 8, _hoisted_3),
      _createElementVNode("img", {
        class: "m-1",
        onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.setLanguagePortoghese();}),
        src: _ctx.portoghese,
        id: "portoghese"
      }, null, 8, _hoisted_4),
      _createElementVNode("img", {
        class: "m-1",
        onClick: _cache[2] || (_cache[2] = ($event: any) => {_ctx.setLanguageItalian();}),
        src: _ctx.italian,
        id: "italian"
      }, null, 8, _hoisted_5)
    ])
  ]))
}