import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slogan = _resolveComponent("Slogan")!
  const _component_AboutUs = _resolveComponent("AboutUs")!
  const _component_WhyHaveAWebsite = _resolveComponent("WhyHaveAWebsite")!
  const _component_Technologies = _resolveComponent("Technologies")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Slogan),
    _createVNode(_component_AboutUs),
    _createVNode(_component_WhyHaveAWebsite),
    _createVNode(_component_Technologies)
  ], 64))
}